import {createStore} from 'redux';

const initialstate = {
    infoUser: 0,
};

const reducer = (state = initialstate, action) => {

    if (action.type === "INFO_USUARIO") {
        return {
            ...state,
            infoUser: action.infoUser
        };
    }

    // console.log(action);
    return state;

};


export default createStore(reducer);
